body{
    background-color: rgb(154, 163, 163);
    color: #fff;
}

.cards__container{
 display: flex;
 flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.heading{
    text-align: center;
}